import {
  faBuilding,
  faEnvelope,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "./Card";
import { Button } from "./Button";
import { Testimonials } from "./Testimonials";

const PricingTable = () => {
  const plans = [
    {
      name: "1 Month",
      price: 19,
      discountedPrice: 9.5,
      period: "1 month",
      highlighted: false,
      description: "Trying it out",
      paymentLink: import.meta.env.VITE_PAYMENT_LINK_MONTHLY,
    },
    {
      name: "3 Months",
      price: 33,
      discountedPrice: 16.5,
      period: "3 months",
      highlighted: true,
      recommended: true,
      description: "Actively looking",
      paymentLink: import.meta.env.VITE_PAYMENT_LINK_QUARTERLY,
    },
    {
      name: "1 Year",
      price: 60,
      discountedPrice: 30,
      period: "1 year",
      highlighted: false,
      description: "Most cost effective",
      paymentLink: import.meta.env.VITE_PAYMENT_LINK_YEARLY,
    },
  ];

  return (
    <div className="relative my-6 md:my-12">
      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-bold text-gray-50 mb-2">
          Next Level Jobs <span className="italic text-lime-400">Pro</span>
        </h2>
        <h3 className="text-xl text-gray-300 mb-6">Get More Job Interviews</h3>
        <ul className="text-gray-300 mb-8 list-none">
          <li>✅ Unlock all jobs</li>
          <li className="text-sm text-gray-400 ml-6 mb-2">
            New companies and jobs added every week
          </li>
          <li>✅ Apply before anyone else</li>
          <li className="text-sm text-gray-400 ml-6 mb-2">
            Alerts within 6 hours of new jobs matching your criteria
          </li>
          <li>✅ Cheapest price ever with 50% off until launch</li>
          <li className="text-sm text-gray-400 ml-6 mb-2">
            Provide feedback and influence features as an early adopter
          </li>
        </ul>
        <div className="bg-lime-400 text-slate-900 px-4 py-1 rounded-full text-sm font-bold mb-2 md:mb-16">
          50% off - Limited time offer!
        </div>
        <div
          id="signup-form-jobalert"
          className="flex flex-col md:flex-row w-full justify-center items-stretch gap-6"
        >
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`w-full md:w-1/3 mx-auto max-w-sm rounded-lg p-6 ${
                plan.highlighted
                  ? "bg-slate-800 border-2 border-gray-700 md:scale-110 md:z-10"
                  : "bg-transparent"
              } relative flex flex-col justify-between`}
            >
              {plan.recommended && (
                <div className="absolute -top-4 right-[-10px] bg-lime-500 text-slate-900 px-2 py-1 rounded-full text-xs font-bold">
                  Recommended
                </div>
              )}
              <div>
                <h3 className="text-2xl font-bold mb-4 text-gray-50">
                  {plan.name}
                </h3>
                <p className="text-4xl font-bold mb-2 text-gray-50">
                  <span className="line-through text-gray-500 text-2xl mr-2">
                    €
                    {plan.price % 1 === 0
                      ? Math.floor(plan.price)
                      : plan.price.toFixed(2)}
                  </span>
                  €
                  {plan.discountedPrice % 1 === 0
                    ? Math.floor(plan.discountedPrice)
                    : plan.discountedPrice.toFixed(2)}
                </p>
                <p className="text-gray-500 text-sm mb-6">
                  €
                  {(
                    plan.discountedPrice /
                    (plan.period === "1 year"
                      ? 12
                      : plan.period === "3 months"
                        ? 3
                        : 1)
                  ).toFixed(2)}{" "}
                  per month
                </p>
                <p className="text-gray-300 mb-4">{plan.description}</p>
              </div>
              <Button
                variant={plan.highlighted ? "primary" : "outlined"}
                className={`w-full mt-auto ${
                  plan.highlighted
                    ? "bg-gray-700 hover:bg-gray-600"
                    : "bg-gray-800 hover:bg-gray-700"
                }`}
                to={plan.paymentLink}
              >
                Choose Plan
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CTAPoint = ({
  icon,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
}) => {
  return (
    <div className="mr-auto ml-0 flex flex-row items-center justify-center gap-4">
      <FontAwesomeIcon width={30} icon={icon} className="text-gray-300" />
      <div className="text-left">
        <p className="text-md md:text-lg text-gray-300">{title}</p>
        <p className="text-sm md:text-md text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export const Features = () => {
  return (
    <Card>
      <div className="p-5 max-w-xl flex gap-5 flex-col m-auto">
        <CTAPoint
          icon={faEnvelope}
          title="Have an edge over other candidates"
          description="Be the first to know when companies post a new job."
        />
        <CTAPoint
          icon={faBuilding}
          title="A curated list of companies"
          description="Companies don't pay to be listed. We only list top tech companies."
        />
        <CTAPoint
          icon={faMagnifyingGlass}
          title="Browse and discover great jobs"
          description="Conveniently browse and discover new opportunities."
        />
      </div>
    </Card>
  );
};

export const SignupForm = () => {
  return (
    <>
      <Card
        className="mt-16 max-w-3xl justify-stretch w-full flex flex-col gap-8 bg-gradient-to-r from-lime-400 via-purple-400 to-gray-400 bg-[length:400%_400%] animate-border border-none"
        style={{ padding: "2px" }}
      >
        <div className="block bg-gray-900 rounded-2xl overflow-hidden p-0.5 sm:p-0.5 p-4">
          <PricingTable />
        </div>
      </Card>
      <div className="mt-12">
        <Testimonials />
      </div>
    </>
  );
};
